import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ConnectivityService } from './services/connectivity/connectivity.service';
import { StorageService } from './services/storage/storage.service';
import { AudioService } from './services/audio/audio.service';
import { UnmuteService } from './services/audio/unmute.service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
    ],
    providers: [
        NativeStorage,
        ConnectivityService,
        StorageService,
        AudioService,
        UnmuteService,
        { 
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy 
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
