import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { Tune } from '../shared/interfaces/tune.model';
import { ConnectivityService } from './connectivity/connectivity.service';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  tunes: Tune[]= [];

//  public serverURL = 'http://192.168.1.3/audiator';
  public serverURL = 'https://spielmaschine.leascherer.ch';
  private libraryUrl = this.serverURL+'/api/tunes/read.php';
  private singleUrl = this.serverURL+'/api/tunes/read_single.php';

  constructor(private http: HttpClient, private nativeStorage: NativeStorage,
    private connectivityService: ConnectivityService) {

      console.log('Library Service Created');

      this.nativeStorage.getItem('library')
        .then(
          data => {
            this.tunes.length = 0;
            const tunes: Tune[] = data;
            this.tunes.push(...tunes);
//            console.log('Library loaded from Storage', this.tunes);
          },
          error => console.error('Error loading library', error)
        );

      this.connectivityService.appIsOnline$.subscribe(online => {

        if (online) {
          this.http.get<Tune[]>(this.libraryUrl)
            .subscribe(
              (tunes) => {
                this.nativeStorage.setItem('library', tunes)
                  .then(
                    () => console.log('Stored library!'),
                    error => console.error('Error storing library', error)
                  );
                this.tunes.length = 0;
                this.tunes.push(...tunes);
                for(const t of this.tunes) {
                  t.loaded = false;
                }
//                console.log('loaded tunes from server', this.tunes);
              }
            );
        }
      });
  }

  getTunes(): Observable<Tune[]> {
//    console.log('getTunes', this.tunes);
    return of(this.tunes);
  }

  getTune(tuneId: number): Tune {
    // eslint-disable-next-line eqeqeq
    const result = this.tunes.filter(t => t.id == tuneId);
    if( result.length > 0 ) {
      return result[0];
    }
    console.error('Tune with id could not be found in library ', tuneId);
    return null;
  }

  loadTuneFromServer(tuneId: number): Observable<Tune> {
    const tune: Tune = this.getTune(tuneId);

    if( tune != null) {
      const params = new HttpParams()
      .set('id', tune.id);
//      console.log('loadTuneFromServer: '+params+', '+this.singleUrl);

      return this.http.get<Tune>(this.singleUrl, {params});
    }
    return null;
  }
}
