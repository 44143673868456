import { Injectable } from '@angular/core';
import { AudioService } from './audio.service';


const USER_ACTIVATION_EVENTS = [
  'auxclick',
  'click',
  'contextmenu',
  'dblclick',
  'keydown',
  'keyup',
  'mousedown',
  'mouseup',
  'touchstart'
];

@Injectable({
  providedIn: 'root'
})
export class UnmuteService {

  // state can be 'blocked', 'pending', 'allowed'
  private htmlAudioState = 'blocked';
  private webAudioState = 'blocked';

  private audio;
  private context;
  private source;

  private sampleRate = (new AudioContext()).sampleRate;
  private silentAudioFile = this.createSilentAudioFile(this.sampleRate);

  constructor(private audioService: AudioService) {
    console.log('Unmute service started');

    USER_ACTIVATION_EVENTS.forEach(eventName => {
/*      window.addEventListener(
        eventName, this.handleUserActivation, { capture: true, passive: true }
      );*/
/*      document.addEventListener(eventName, () => {
        this.handleUserActivation();
      })*/
    });
  }

  // Return a seven samples long 8 bit mono WAVE file
  private createSilentAudioFile(sampleRate) {
    console.log('createSilentAudioFile ', sampleRate);
    const arrayBuffer = new ArrayBuffer(10);
    const dataView = new DataView(arrayBuffer);

    dataView.setUint32(0, sampleRate, true);
    dataView.setUint32(4, sampleRate, true);
    dataView.setUint16(8, 1, true);

    const missingCharacters =
      window.btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
        .slice(0, 13);

    return `data:audio/wav;base64,UklGRisAAABXQVZFZm10IBAAAAABAAEA${missingCharacters}AgAZGF0YQcAAACAgICAgICAAAA=`;
  }

  private handleUserActivation() {

    console.log('handleUserActivation ', this.htmlAudioState, this.webAudioState);

    if (this.htmlAudioState === 'blocked') {
      this.htmlAudioState = 'pending';
      this.createHtmlAudio();
    }
    if (this.webAudioState === 'blocked') {
      this.webAudioState = 'pending';
      this.createWebAudio();
    }
  }

  private createHtmlAudio() {
    console.log('createHtmlAudio');
    this.audio = document.createElement('audio');

    this.audio.setAttribute('x-webkit-airplay', 'deny'); // Disable the iOS control center media widget
    this.audio.preload = 'auto';
    this.audio.loop = true;
    this.audio.src = this.silentAudioFile;
    this.audio.load();

    this.audio.play().then(
      () => {
        this.htmlAudioState = 'allowed';
        this.cleanup();
      },
      () => {
        this.htmlAudioState = 'blocked';

        this.audio.pause();
        this.audio.removeAttribute('src');
        this.audio.load();
        this.audio = null;
      }
    );
  }

  private createWebAudio() {
    console.log('createWebAudio');

//    this.source = this.audioService.audioContext.createBufferSource();
//    this.source.buffer = this.audioService.audioContext.createBuffer(1, 1, 22050); // .045 msec of silence
//    this.source.connect(this.audioService.audioContext.destination);
    this.source.start();
    console.log('createWebAudio start');


/*    if (this.audioService.audioContext.state === 'running') {
      console.log('createWebAudio running');
      this.webAudioState = 'allowed';
      this.cleanup();
    } else {
      console.log('createWebAudio error');
      this.webAudioState = 'blocked';

      this.source.disconnect();
      this.source = null;

    }*/
  }

  private cleanup() {
    console.log('cleanup Activation Audio');
    if (this.htmlAudioState !== 'allowed' || this.webAudioState !== 'allowed') { return; }

    USER_ACTIVATION_EVENTS.forEach(eventName => {
      window.removeEventListener(eventName, this.handleUserActivation);
    });
  }
}
