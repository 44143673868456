import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'library',
    pathMatch: 'full'
  },
  {
    path: 'library',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/library/library.module').then( m => m.LibraryPageModule)
      },
      {
        path: ':tuneId',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/player/player.module').then( m => m.PlayerPageModule)
          },
          {
            path: 'arrangement',
            loadChildren: () => import('./pages/modal/arrangement/arrangement.module').then( m => m.ArrangementPageModule)
          },
          {
            path: 'mixer',
            loadChildren: () => import('./pages/modal/mixer/mixer.module').then( m => m.MixerPageModule)
          }
        ]
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'library'
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
