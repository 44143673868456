import { AudioEngine, PreloadOptions } from '@musicdose/audioengine';
import { Injectable } from '@angular/core';
//import { AudioContext, AudioContextModule } from 'angular-audio-context';
import { Tune, Track, Tonality, Speed, InstrumentGroup, Instrument, Part } from '../../shared/interfaces/tune.model';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  public duration = 1;
  public currentTime = 0;

  get currentBeat(): number {
    if(this.speed) {
      return Math.floor(this.speed.speed/60*(this.currentTime));
    }
    return 0;
  }

  get currentBar(): number {
    if(this.tune && this.tune.meters[0]) {
      const meter = this.tune.meters[0].meter;
      return Math.floor(this.currentBeat/meter)+1;
    }
    return 0;
  }

  get currentPart(): Part {
    if(this.tune) {
      for (const part of this.tune.parts) {
        if (part.barstart<=this.currentBar && part.barend>=this.currentBar) {
          return part;
        }
      }
    }
    return null;
  }

  get currentPartIsLooping(): boolean {
    if(this.loopStartPart===this.currentPart && this.loopEndPart===this.currentPart) {
      return true;
    }
    return false;
  }

  get isStartLoopPart(): boolean {
    if(this.loopStartPart===this.currentPart) {
      return true;
    }
    return false;
  }

  get isEndLoopPart(): boolean {
    if(this.loopEndPart===this.currentPart) {
      return true;
    }
    return false;
  }

  get hasPreviousPart(): boolean {
    if(this.tune) {
      const index = this.tune.parts.indexOf(this.currentPart);
      if(index > 0) {
        return true;
      }
    }
    return false;
  }

  get hasNextPart(): boolean {
    if(this.tune) {
      const index = this.tune.parts.indexOf(this.currentPart);
      if(index < this.tune.parts.length-1) {
        return true;
      }
    }
    return false;
  }

  get mainVolume(): number {
    return this.mainGain;
  }

  set mainVolume(vol: number) {
    this.mainGain = vol;
    const instruments = this.getInstruments();
    for (const instrument of instruments) {
      this.setInstrumentVolume(instrument);
    }
  }

  get currentTonality(): Tonality {
    return this.tonality;
  }

  get currentSpeed(): Speed {
    return this.speed;
  }

  get hasMultipleTonalities(): boolean {
    if(this.tune) {
      return this.tune.tonalities.length > 1;
    }
    return false;
  }

  get tonalities(): Tonality[] {
    if(this.tune) {
      return this.tune.tonalities;
    }
    return null;
  }

  get isPlaying(): boolean {
    return this.playing && !this.paused;
  }

  private isUnlocked = false;

  private updateInterval = 4;
  private playing = false;           // Boolean parameter to deal with "Play" & "Stop" buttons
  private paused = false;

  private tune: Tune;
  private tonality: Tonality;
  private speed: Speed;

  private loopStart?: number;
  private loopStartPart?: Part;
  private loopEnd?: number;
  private loopEndPart?: Part;

  private mainGain = 1;

//  private serverUrl = 'http://192.168.1.3/audiator';
  private serverUrl = 'https://spielmaschine.leascherer.ch';

private subscriptions = new Subscription();

  constructor(/*private backgroundMode: BackgroundMode, */private http: HttpClient) {
    AudioEngine.initAudio();

    setInterval(() => {
      AudioEngine.getCurrentTime().then(res => {
        this.currentTime = res.value

      })
    }, this.updateInterval);

  }

  public async unloadTune() {
    AudioEngine.unloadAudio();
    console.log('unload tune');

    this.resetLoop(); 

    this.tune = null;
    this.tonality = null;
    this.speed = null;

    this.subscriptions.unsubscribe();
//    this.audioContext = new (AudioContext || webkitAudioContext)();
//    console.log('Created Audio Context: '+this.audioContext);
  }

  public async loadTune(tune: Tune) {
    this.tune = tune;

//    console.log('AudioPlayer load tune: ', this.tune);

    console.log('load Tune: ', this.tune);
    for (let t of this.tune.tonalities) {
      for (let speed of t.speeds) {
        for (let instrgroup of speed.groups) {
          instrgroup.open = true;
          for (let instrument of instrgroup.instruments) {
            instrument.playing = true;
            instrument.instrVolume = 1;
          }
        }
      }
    }

    for (const p of this.tune.parts) {
      p.selected = true;
    }

    console.log('AudioPlayer loaded tune: ', this.tune);
  }

  public play(startTime: number=this.currentTime) {
    console.log('Audio Service: lets play ', this.playing);
    this.paused = false;
    AudioEngine.play();
    this.playing = true;
  }

  public pause() {
 //   this.backgroundMode.disable();
    AudioEngine.pause();

    this.paused = true;
  }

  public stop() {
    AudioEngine.stop();
    this.playing = false;
  }

  public selectTonality(tonality: Tonality) {
    this.stop();
    this.tonality = tonality;
    if(this.tonality.speeds[0]) {
      this.selectSpeed(this.tonality.speeds[0]);
    }
  }

  public async  selectSpeed(speed: Speed) {
    this.stop();
    AudioEngine.unloadAudio();
    const instruments = this.getInstruments();
    for (const instrument of instruments) {
      for (const track of instrument.tracks) {
          let options = {
            'assetPath' : track.path,
            'assetId': track.id,
            'volume': 1,
            'audioChannelNum': 1,
            'isUrl': true
          };
          await AudioEngine.preloadAudio(options);
      }
    }

    this.speed = speed;
    await AudioEngine.getDuration().then( duration => {
      this.duration=duration.value;
      console.log("Set Duration :"+duration.value);
    });

    console.log('set speed: '+this.speed.speed);
  }

  public setLoopParts(start: Part, end: Part) {
    this.loopStartPart = start;
    this.loopEndPart = end;
    this.setLoopTimes(this.getBarTime(start.barstart),
    this.getBarTime(end.barend+1));
  }

  public resetLoop() {
    this.loopStartPart = null;
    this.loopStart = null;
    this.loopEndPart = null;
    this.loopEnd = null;

    AudioEngine.setLoop({active: false})
  }

  public muteInstrument(instr: Instrument, mute: boolean) {
    instr.muted = mute;
    if(instr.muted) {
      instr.lastGainValue = instr.instrVolume;
      instr.instrVolume = 0;
      for (const track of instr.tracks) {
        AudioEngine.setVolume({trackid: track.id, volume: 0})
      }
    } else {
      instr.instrVolume = instr.lastGainValue;
      console.log("Last Gain Value= "+instr.lastGainValue);
      for (const track of instr.tracks) {
        AudioEngine.setVolume({trackid: track.id, volume: instr.lastGainValue})
      }
    }
  }

  public setInstrumentVolume(instr: Instrument) {
    for (const track of instr.tracks)
      AudioEngine.setVolume({trackid: track.id, volume: this.mainVolume*instr.instrVolume})

  }

  public soloInstrument(instr: Instrument) {
    instr.solo = !instr.solo;
    const instruments = this.getInstruments();
    for (const instrument of instruments) {
      if(instrument !== instr) {
        this.muteInstrument(instrument, instr.solo);
      }
    }
  }

  public getInstrumentGroups() {
    const out: InstrumentGroup[] = [];
    if (this.speed) {
      for (const instrgroup of this.speed.groups) {
        out.push(instrgroup);
      }
    }
    return out;
  }

  public jumpToNextPart() {
    const index = this.tune.parts.indexOf(this.currentPart);
    if(index < this.tune.parts.length-1) {
      this.jumpToPart(this.tune.parts[index+1], true);
    }
  }

  public jumpToPreviousPart() {
    const index = this.tune.parts.indexOf(this.currentPart);
    if(index > 0) {
      this.jumpToPart(this.tune.parts[index-1], true);
    }
  }

  public jumpToPart(part: Part, withPickup: boolean) {
    let time = this.getBarTime(part.barstart);
    if(withPickup) {
      time -= part.pickup/this.speed.speed*60;
    }
    this.setCurrentTime(time);
  }

  public setCurrentTime(time: number) {
    const wasPaused = this.paused;
    const wasPlaying = this.playing;
    if (this.playing) {
//      AudioEngine.pause();
      this.stop();
    }
    AudioEngine.jumpTo({seconds: time});
    if(!wasPaused && wasPlaying) {
      this.play(this.currentTime);
//      AudioEngine.play();
    }
  }

  public jumpToBar(bar: number) {
    this.setCurrentTime(this.getBarTime(bar));
  }

  public getBarTime(bar: number): number {
    return (bar-1)*this.tune.meters[0].meter/this.speed.speed*60;
  }

  private getInstruments(): Instrument[] {
    const out: Instrument[] = [];
    if(this.speed) {
      for (const instrgroup of this.speed.groups) {
        for (const instrument of instrgroup.instruments) {
          out.push(instrument);
        }
      }
    }

    return out;
  }

  private getTracks(): Track[] {
    const out: Track[] = [];
    const instruments = this.getInstruments();
    for (const instrument of instruments) {
      for (const track of instrument.tracks) {
        out.push(track);
      }
    }

    return out;
  }

  private setLoopTimes(start: number, end: number) {
    this.loopStart = start;
    this.loopEnd = end;

    AudioEngine.setLoop({active: true})
    AudioEngine.setLoopTimes({from: this.loopStart, to: this.loopEnd})
  }
}
